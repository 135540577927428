.ServiceStatus{
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: .25rem;
    background-color: #ffffff;
  }

.ServiceName{
    font-weight: bold;
    padding: 5px;
    background-color: #f7f7f7;
    border-bottom: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem .25rem 0px 0px;
}

.Dependencies{
  margin: 10px;
}